/* PdfViewer.css */
.pdf-viewer {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.controls {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination button {
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.content {
  border: 1px solid #ccc;
  padding: 10px;
}
.textViewer {
  width: 100%;
  height: 600px;
  position: relative;
}

.textContent {
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  background: #fff;
  border: 1px solid #ccc;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
}

.textPage {
  min-height: 600px; /* Roughly one page height */
  border-bottom: 1px dashed #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.textPage h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.textPage pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.textNavigation {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.scrollLegend {
  margin-left: 10px;
}

.scrollBar {
  width: 200px;
}
.textContainer {
  width: 100%;
  height: 600px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
}

.textContent {
  white-space: pre-wrap; /* Preserves whitespace and wraps text */
  word-wrap: break-word; /* Breaks long words */
  font-family: monospace; /* Better for raw text */
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.error {
  color: red;
  text-align: center;
  padding: 20px;
}
.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pageSelect {
  padding: 5px;
  border-radius: 4px;
}