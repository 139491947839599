.sources {
  padding: 2.5rem 1.5rem;
  background: linear-gradient(135deg, #1e293b 0%, #2d3748 100%); /* Darker gradient */
  border-top: 1px solid #4b5563; /* Muted slate border */
}

.sources h2 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #e2e8f0; /* Light slate */
  margin-bottom: 1.25rem;
  text-align: center;
  text-transform: uppercase;
}

.sourcesList {
  list-style: none;
  padding: 0;
  max-width: 900px;
  margin: 0 auto;
}

.sourceItem {
  margin-bottom: 1.75rem;
  padding: 1.25rem;
  background: #374151; /* Darker slate for cards */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.sourceItem:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.25);
}

.sourceTitle {
  font-size: 1.3rem;
  font-weight: 600;
  color: #d1d5db; /* Light gray */
  margin-bottom: 0.75rem;
}

.sourceUrl, .sourceLink {
  font-size: 1rem;
  color: #60a5fa; /* Matching blue */
}

.sourceLink:hover {
  color: #3b82f6;
  text-decoration: underline;
}

.sourceDescription {
  font-size: 0.95rem;
  color: #9ca3af; /* Muted gray */
  line-height: 1.6;
}