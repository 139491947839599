/* Full-width .pageHeader with centered content */
.pageHeader {
  padding: 3rem 2rem;
  background: linear-gradient(135deg, #334155 0%, #475569 100%);
  text-align: center;
  border-bottom: 1px solid #64748b;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.pageHeader > * { /* Targets direct children like .title, .imageContainer, .synopsis */
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 2.75rem;
  font-weight: 800;
  color: #e2e8f0; /* Light slate for contrast */
  margin-bottom: 1.75rem;
  letter-spacing: -0.025em;
  text-transform: capitalize;
}

.title a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.title a:hover {
  color: #60a5fa; /* Softer blue for hover */
  text-shadow: 0 2px 4px rgba(96, 165, 250, 0.4); /* Matching glow */
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  max-width: 900px;
}

.headerImage {
  max-width: 50vw;
  max-height: 35vh;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25); /* Darker shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.headerImage:hover {
  transform: scale(1.08);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.35);
}

.synopsis {
  margin-top: 2rem;
  padding: 0 1.5rem;
}

.synopsis h2 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #d1d5db; /* Light gray for contrast */
  margin-bottom: 0.75rem;
  letter-spacing: 0.025em;
}

.synopsis p {
  font-size: 1.15rem;
  color: #9ca3af; /* Muted gray for readability */
  line-height: 1.7;
  max-width: 900px;
  margin: 0 auto 1.5rem;
}

.keywords {
  font-size: 1rem;
  color: #bfdbfe; /* Soft blue-gray for keywords */
  font-style: italic;
  background: rgba(71, 85, 105, 0.5); /* Semi-transparent slate backdrop */
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  display: inline-block;
}
