/*src/components/Body/Body.module.css*/
/* Add this to your CSS file (e.g., Body.module.css if using CSS modules, or a global stylesheet) */

/* Full-width .body with centered content */
.body {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  background: #1e293b; /* Matches page background */
  color: #d1d5db;
}

/* Flex container for the main layout */
.container {
  display: flex;
  width: 100%;

}
.content {
  font-size: 1.15rem;
  color: #9ca3af; /* Muted gray for readability */
  line-height: 1.7;
  max-width: 1200px;
  margin: 0 auto 1.5rem;
}
